import {Paper, styled} from '@mui/material';
import Typography from '@mui/material/Typography';
import {useCallback, useEffect} from 'react';
import {
  maxLength,
  minLength,
  minValue,
  NumberInput,
  required,
  SelectInput,
  TextInput,
  useEditContext,
} from 'react-admin';
import {useFormContext} from 'react-hook-form';

import {
  PaymentMethod,
  Product,
  ProductAuthenticationMode,
  ProductHookUrlVersion,
  useGetProductByDatamatrixAndPartnerLazyQuery,
  useIsMerchantIdValidForProductLazyQuery,
} from '../../api/generated';
import {CreateEditTopBar} from '../../components/CreateEditTopBar';
import {AutocompleteArrayInput} from '../../components/Form/Inputs/AutocompleteArrayInput';
import {CheckBoxBooleanInput} from '../../components/Form/Inputs/CheckboxBooleanInput';
import {StyledSwitch} from '../../components/Form/Inputs/StyledSwitch';
import {PageLayout} from '../../components/PageLayout';
import {useFormValues} from '../../hooks/useFormValues';
import {palette} from '../../theme/palette';
import {
  alphanumericValidator,
  numberInputFormatFromCents,
  numberInputParseToCents,
  numericValidator,
  uniqueValidator,
} from '../../utils/input.utils';
import {useProductFormQueries} from './helpers';
import {NeptuneLogoUploadInput} from './NeptuneLogoUploadInput';
import {OauthBodyInput} from './OauthBodyInput';
import {ProductCredentialsInput} from './ProductCredentialsInput';

const FormContainer = styled('div')({
  display: 'grid',
  width: '100%',
  gap: '24px',
  gridTemplateColumns: '1fr 1fr',
});

const StyledPaper = styled(Paper)({
  display: 'flex',
  backgroundColor: 'white',
  padding: '32px',
  flexDirection: 'column',
  rowGap: '24px',
  '& .MuiFormHelperText-root:not(.Mui-error)': {
    display: 'none',
  },

  '& .MuiFormControlLabel-root': {
    margin: 0,
  },

  '& .MuiCheckbox-root': {
    padding: '0',
    marginRight: '10px',
  },
});

const PaperTitle = styled(Typography)({
  marginBottom: '24px',
});

const CheckBoxContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: '12px',
});

const CardTitleContainer = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
});

const InfoParagraph = styled('p')({
  color: 'rgb(144, 144, 144)',
});

export interface ProductFormProps {
  title?: string;
  NavigationButton?: JSX.Element;
}

const allowedPaymentMethodsChoices = Object.values(
  PaymentMethod as Record<string, string>,
).map(value => {
  return {
    id: value,
    name: value.replace('_', ' '),
  };
});

function validateUrl(value: unknown): string | undefined {
  if (typeof value === 'string') {
    try {
      const url = new URL(value); // URL will throw on invalid urls
      if (url.protocol !== 'http:' && url.protocol !== 'https:') {
        return "L'URL doit être http ou https.";
      }
    } catch {
      return 'URL invalide.';
    }
  }
}

export const ProductForm = ({
  title,
  NavigationButton,
}: ProductFormProps): JSX.Element => {
  const {record, resource} = useEditContext<Product>(); // To check if we're in a create or edit context.
  const cbsMerchantIdInitialValue = record ? record.cbsMerchantId : null;
  const productId = record ? record.id : undefined;

  const isInCreateContext = resource === null;

  const {categories, partners} = useProductFormQueries();

  const [getProductByDatamatrixAndPartner] =
    useGetProductByDatamatrixAndPartnerLazyQuery({fetchPolicy: 'no-cache'});

  const [isMerchantIdValidForProduct] =
    useIsMerchantIdValidForProductLazyQuery();

  const {
    trigger,
    setValue,
    formState: {isSubmitted},
  } = useFormContext();

  const {
    authenticationMode,
    partner,
    category: formValueCategory,
    minimumPaymentInCents,
    ExistingMerchantAccount,
    oauthBody,
  } = useFormValues<{
    authenticationMode: ProductAuthenticationMode | null;
    partner?: {id: string};
    category?: {id: string};
    minimumPaymentInCents: number;
    ExistingMerchantAccount: boolean;
    oauthBody: Product['oauthBody'];
  }>();
  const partnerId = partner?.id;
  const categoryId = formValueCategory?.id;

  const uniqueDatamatrixProductIdValidator = uniqueValidator(
    async (datamatrixProductId: string) => {
      if (!partnerId) {
        return null;
      }
      const {data} = await getProductByDatamatrixAndPartner({
        variables: {
          datamatrixProductId,
          partnerId,
        },
      });

      // If the result contains data but the id is the same as the current product id
      // then we don't return the result as it shouldn't trigger a unique validation error
      if (data?.getProductByDatamatrixAndPartner?.id === productId) {
        return null;
      }
      return data?.getProductByDatamatrixAndPartner;
    },
    undefined, // undefined because we want to trigger validation when the partner.id changes even if datamatrixProductId is still the same
    'Un autre produit avec le même datamatrixProductId et appartenant au même partenaire existe déjà',
  );

  useEffect(() => {
    if (!ExistingMerchantAccount) {
      setValue('cbsMerchantId', null);
    }
  }, [ExistingMerchantAccount, setValue]);

  // datamatrixProductId's validation depends on partner.id field
  // so we need to trigger it whenever the partnerId changes
  useEffect(() => {
    if (isSubmitted && partnerId) {
      void trigger('datamatrixProductId');
    }
  }, [isSubmitted, partnerId, trigger]);

  // maximumPaymentInCents's validation depends on minimumPaymentInCents field
  // so we need to trigger it whenever minimumPaymentInCents changes
  useEffect(() => {
    if (isSubmitted && minimumPaymentInCents) {
      void trigger('maximumPaymentInCents');
    }
  }, [isSubmitted, minimumPaymentInCents, trigger]);

  const categoryChoices = categories?.map(category => ({
    id: category.id,
    name: category.label,
  }));

  const partnerChoices = partners?.map?.(partner => ({
    id: partner.id,
    name: partner.name,
  }));

  const hookUrlVersionChoices = Object.values(ProductHookUrlVersion).map(v => ({
    id: v,
    name: v,
  }));

  const category = categories?.find(c => c.id === categoryId);
  const categoryCode = category?.code ?? '';
  const isDepositOrWithdrawal = ['RET', 'DEP'].includes(categoryCode);
  const isPeaOrAvp = ['PEA', 'AVP'].includes(categoryCode);
  const mustHaveApiUrl = isDepositOrWithdrawal || isPeaOrAvp;

  useEffect(() => {
    // Force set some properties for DEP/RET products
    if (isDepositOrWithdrawal) {
      setValue('canExpire', true);
      setValue('canBePartiallyPaid', false);
      setValue('canBePaidByThirdParty', false);
      setValue('allowedPaymentMethods', [PaymentMethod.Cash]);
      setValue('cbsMerchantId', cbsMerchantIdInitialValue);
    } else {
      setValue('cbsMerchantId', undefined);
    }
  }, [isDepositOrWithdrawal, setValue, cbsMerchantIdInitialValue]);

  // Force checkbox to false on PEA/AVP products
  useEffect(() => {
    if (isPeaOrAvp) {
      setValue('canExpire', false);
      setValue('canBePartiallyPaid', false);
      setValue('canBePaidByThirdParty', false);
    }
  }, [isPeaOrAvp, setValue]);

  // Force oauthBody
  // Tried a 1000 things to fix a bug on update form (having oauthBody key/value undefined)
  // without success so ended up doing this dirty workaround...
  useEffect(() => {
    setTimeout(() => {
      setValue(
        'oauthBody',
        record?.oauthBody ?? [
          {key: 'grant_type', value: 'client_credentials'},
          {key: 'scope', value: ''},
        ],
      );
    }, 500);
  }, [record, setValue]);

  const existingMerchantRefWithCategoryValidator = useCallback(
    async (cbsMerchantId: string): Promise<string | null> => {
      if (ExistingMerchantAccount && !cbsMerchantId) {
        return 'Vous devez donner la référence du compte commerçant';
      }
      // ExistingMerchantAccount is undefined when editing products
      // ExistingMerchantAccount is false when the checkbox is not On at creation form
      if (ExistingMerchantAccount === false) {
        return null;
      }
      if (!categoryId) {
        return 'Vous devez choisir une catégorie';
      }

      const {data} = await isMerchantIdValidForProduct({
        variables: {
          cbsMerchantId,
          categoryId,
          productId: isInCreateContext ? undefined : productId,
        },
      });

      if (data && !data.isMerchantIdValidForProduct) {
        return 'La référence du compte commerçant est invalide ou elle est déja utilisé par un autre produit de la même catégorie';
      }

      return null;
    },
    [
      ExistingMerchantAccount,
      categoryId,
      isMerchantIdValidForProduct,
      isInCreateContext,
      productId,
    ],
  );

  if (!categories || !partners) {
    return <div>Chargement ...</div>;
  }

  return (
    <PageLayout>
      <CreateEditTopBar
        title={title}
        NavigationButton={NavigationButton}
        resourceName="Product"
      />
      <FormContainer>
        <StyledPaper>
          <CardTitleContainer>
            <PaperTitle variant="h1" color="secondary">
              Produit
            </PaperTitle>
            <StyledSwitch defaultValue={false} label="Actif" source="active" />
          </CardTitleContainer>

          <SelectInput
            source="category.id"
            choices={categoryChoices}
            validate={required()}
          />

          <SelectInput
            source="partner.id"
            choices={partnerChoices}
            validate={required()}
          />

          <TextInput source="name" validate={required()} />

          <TextInput
            source="datamatrixProductId"
            validate={[
              required(),
              minLength(2),
              maxLength(5),
              alphanumericValidator,
              uniqueDatamatrixProductIdValidator,
            ]}
          />

          <TextInput
            fullWidth
            multiline
            rows={5}
            source="serviceFeeDescription"
          />
        </StyledPaper>
        <StyledPaper>
          <PaperTitle variant="h1" color="secondary">
            Règles de gestion
          </PaperTitle>
          <AutocompleteArrayInput
            source="allowedPaymentMethods"
            choices={allowedPaymentMethodsChoices}
            validate={required()}
            readOnly={isDepositOrWithdrawal}
          />
          <NumberInput
            source="minimumPaymentInCents"
            min={0.01}
            step={0.01}
            format={numberInputFormatFromCents}
            parse={numberInputParseToCents}
            validate={[
              required(),
              minValue(
                0.01,
                'Le montant minimum autorisé doit être supérieur ou égal à 0.01',
              ),
            ]}
          />
          <NumberInput
            source="maximumPaymentInCents"
            min={0.01}
            step={0.01}
            format={numberInputFormatFromCents}
            parse={numberInputParseToCents}
            validate={[
              required(),
              minValue(
                minimumPaymentInCents,
                'Le montant maximum autorisé doit être supérieur au montant minimum autorisé',
              ),
            ]}
          />
          <CheckBoxContainer>
            <Typography variant="body1" color={palette.grey[500]}>
              Détails
            </Typography>
            <CheckBoxBooleanInput
              source="canExpire"
              defaultValue={false}
              disabled={isDepositOrWithdrawal || isPeaOrAvp}
            />
            <CheckBoxBooleanInput
              source="canBePartiallyPaid"
              defaultValue={false}
              disabled={isDepositOrWithdrawal || isPeaOrAvp}
            />
            <CheckBoxBooleanInput
              source="canBePaidByThirdParty"
              defaultValue={false}
              disabled={isDepositOrWithdrawal || isPeaOrAvp}
            />
          </CheckBoxContainer>
        </StyledPaper>

        {isDepositOrWithdrawal && (
          <StyledPaper>
            <CardTitleContainer>
              <PaperTitle variant="h1" color="secondary">
                Paramétrage avec le CBS
              </PaperTitle>
            </CardTitleContainer>
            {isInCreateContext && (
              <StyledSwitch
                defaultValue={false}
                label="Rattacher le produit à un compte existant"
                source="ExistingMerchantAccount"
              />
            )}
            <TextInput
              fullWidth
              source="cbsMerchantId"
              label="#Ref du compte commerçant*"
              validate={[
                numericValidator,
                existingMerchantRefWithCategoryValidator,
              ]}
              disabled={!ExistingMerchantAccount && isInCreateContext}
            />
            <InfoParagraph>
              Rattacher un produit à un compte commerçant existant permet de
              cumuler les opérations de plusieurs produits différents sur un
              même compte dans le CBS. Les frais appliqués sur plusieurs
              produits d&#39;un même type d&#39;opération seront similaires si
              les produits partagent un même compte commerçant.
            </InfoParagraph>
          </StyledPaper>
        )}

        {isDepositOrWithdrawal && (
          <StyledPaper>
            <PaperTitle variant="h1" color="secondary">
              Logo Reçu Neptune
            </PaperTitle>
            <NeptuneLogoUploadInput />
          </StyledPaper>
        )}

        <StyledPaper style={{gridColumn: 'span 2'}}>
          <PaperTitle variant="h1" color="secondary">
            Paramétrage de l&apos;API du partenaire
          </PaperTitle>

          <SelectInput
            source="authenticationMode"
            choices={[
              {id: ProductAuthenticationMode.Basic, name: 'Basic Auth'},
              {id: ProductAuthenticationMode.Oauth, name: 'Oauth'},
            ]}
            format={(v: string | null): string => v ?? 'NONE'}
            parse={(v: string): string | null => (v === 'NONE' ? null : v)}
            defaultValue={null}
            emptyText="Sans authentification"
            emptyValue="NONE" // Empty string ("") does NOT work as an emptyValue. React Admin leaves the select blank instead of showing the correct text.
          />

          <TextInput
            type="url"
            source="apiUrl"
            parse={(value: string): string | null =>
              value === '' ? null : value
            }
            defaultValue={null}
            validate={
              mustHaveApiUrl
                ? [
                    required(
                      'Une URL est obligatoire pour ce type de produit.',
                    ),
                    validateUrl,
                  ]
                : validateUrl
            }
          />

          <SelectInput
            source="hookUrlVersion"
            choices={hookUrlVersionChoices}
            validate={required()}
            defaultValue="V2"
          />

          <TextInput
            type="url"
            source="transactionNoticeUrl"
            parse={(value: string): string | null =>
              value === '' ? null : value
            }
            defaultValue={null}
            validate={validateUrl}
          />

          {authenticationMode === ProductAuthenticationMode.Oauth && (
            <TextInput
              type="url"
              source="authenticationUrl"
              parse={(value: string): string | null =>
                value === '' ? null : value
              }
              defaultValue={null}
              validate={[required(), validateUrl]}
            />
          )}

          {(authenticationMode === ProductAuthenticationMode.Basic ||
            authenticationMode === ProductAuthenticationMode.Oauth) && (
            <StyledPaper>
              <PaperTitle variant="h2" color="secondary">
                Identifiants
              </PaperTitle>
              <ProductCredentialsInput />
            </StyledPaper>
          )}

          {authenticationMode === ProductAuthenticationMode.Oauth && (
            <StyledPaper>
              <CardTitleContainer>
                <PaperTitle variant="h2" color="secondary">
                  Clés/valeurs à envoyer lors de la reqûete d&apos;oauth
                </PaperTitle>
                <StyledSwitch
                  defaultValue={RESSOURCE_OWNER_KEYS.every(key =>
                    record?.oauthBody?.find(row => row.key === key),
                  )}
                  label="ressource owner"
                  source="ressource_owner"
                  onChange={(
                    event: React.ChangeEvent<HTMLInputElement>,
                  ): void => {
                    setValue(
                      'oauthBody',
                      event.target.checked
                        ? [
                            ...(oauthBody ?? []),
                            ...RESSOURCE_OWNER_KEYS.map(key => ({
                              key,
                              value: '',
                            })),
                          ]
                        : oauthBody?.filter(
                            ({key}) => !RESSOURCE_OWNER_KEYS.includes(key),
                          ),
                    );
                  }}
                />
              </CardTitleContainer>

              <OauthBodyInput />
            </StyledPaper>
          )}
        </StyledPaper>
      </FormContainer>
    </PageLayout>
  );
};

const RESSOURCE_OWNER_KEYS = ['username', 'password'];
