import {PermissionDto} from '../../api/generated';
import {PermissionGuard} from '../../components/PermissionGuard';
import {CustomerServiceInfoList} from './CustomerServiceInfoList';
import {SupportFormCategoryList} from './SupportFromCategoryList';

export const CustomerServiceTouchPoint = (): JSX.Element => {
  return (
    <PermissionGuard permission={PermissionDto.CustomerServiceRead}>
      <CustomerServiceInfoList />
      <SupportFormCategoryList />
    </PermissionGuard>
  );
};
