import {Box} from '@mui/material';
import {
  DeleteWithConfirmButton,
  DeleteWithConfirmButtonProps,
  SaveButton,
  Toolbar,
  useRecordContext,
} from 'react-admin';

export const FormToolbar = ({
  canSave = true,
  canDelete = false,
  deleteButtonProps,
}: {
  canSave?: boolean;
  canDelete?: boolean;
  deleteButtonProps?: DeleteWithConfirmButtonProps;
}): JSX.Element => {
  const record = useRecordContext();

  return (
    <Toolbar>
      {canSave && <SaveButton alwaysEnable />}
      <Box flex={1} />
      {record && canDelete && (
        <DeleteWithConfirmButton {...deleteButtonProps} />
      )}
    </Toolbar>
  );
};
