import TurnbackIcon from '@mui/icons-material/KeyboardBackspace';
import {Grid} from '@mui/material';
import {
  Create,
  CreateButton,
  Datagrid,
  DeleteWithConfirmButton,
  Edit,
  EditButton,
  Link,
  List,
  required,
  SimpleForm,
  TextField,
  TextInput,
  WithRecord,
} from 'react-admin';

import {PermissionDto, SupportFormCategory} from '../../api/generated';
import {FormToolbar} from '../../components/Form/FormToolbar';
import {Icons} from '../../components/Icon';
import {ListTopBar} from '../../components/ListTopBar';
import {PermissionGuard} from '../../components/PermissionGuard';
import {usePermissions} from '../../hooks/usePermissions';

export const SupportFormCategoryList = (): JSX.Element => {
  const {can} = usePermissions();
  const actions = [];

  if (can(PermissionDto.CustomerServiceWrite)) {
    actions.push(
      <CreateButton
        variant="contained"
        key="create"
        icon={<Icons.Add isActive size={17} />}
      />,
    );
  }

  return (
    <PermissionGuard permission={PermissionDto.CustomerServiceRead}>
      <List
        disableSyncWithLocation // Prevents url fighting with CustomerServiceInfoList
        resource="SupportFormCategory"
        actions={<ListTopBar actions={actions} />}>
        <Datagrid bulkActionButtons={false}>
          <TextField source="frontCategory" sortable={false} />
          <TextField source="frontSubCategory" sortable={false} />
          <TextField source="backCategory" sortable={false} />
          <TextField source="backSubCategory" sortable={false} />
          {can(PermissionDto.CustomerServiceWrite) && (
            <>
              <EditButton sx={{marginX: '1em', marginY: '0.5em'}} />
              <WithRecord
                label="author"
                render={(record: SupportFormCategory): JSX.Element => (
                  <DeleteWithConfirmButton
                    sx={{marginX: '1em', marginY: '0.5em'}}
                    redirect="/admin/CustomerServiceTouchPoint"
                    confirmTitle={`Supprimer la catégorie ${record.frontSubCategory.replaceAll(
                      ' ',
                      '\u00A0', // NO-BREAK SPACE
                    )}\u00A0?`}
                  />
                )}
              />
            </>
          )}
        </Datagrid>
      </List>
    </PermissionGuard>
  );
};

export const SupportFormCategoryListCreate = (): JSX.Element => {
  return (
    <PermissionGuard permission={PermissionDto.CustomerServiceWrite}>
      <Create
        sx={{width: '100%', maxWidth: '1000px', margin: '0 auto'}}
        redirect="/admin/CustomerServiceTouchPoint">
        <SupportFormCategoryListEditForm />
      </Create>
    </PermissionGuard>
  );
};

export const SupportFormCategoryListEdit = (): JSX.Element => {
  return (
    <PermissionGuard permission={PermissionDto.CustomerServiceWrite}>
      <Edit
        sx={{width: '100%', maxWidth: '1000px', margin: '0 auto'}}
        mutationMode="optimistic"
        redirect="/admin/CustomerServiceTouchPoint">
        <SupportFormCategoryListEditForm />
      </Edit>
    </PermissionGuard>
  );
};

const SupportFormCategoryListEditForm = (): JSX.Element => {
  return (
    <SimpleForm toolbar={<FormToolbar />}>
      <Link to="/admin/CustomerServiceTouchPoint" title="Retour vers la liste">
        <TurnbackIcon />
      </Link>

      <Grid container spacing={2}>
        <Grid item sm={6}>
          <TextInput fullWidth source="frontCategory" validate={[required()]} />
        </Grid>
        <Grid item sm={6}>
          <TextInput
            fullWidth
            source="frontSubCategory"
            validate={[required()]}
          />
        </Grid>
        <Grid item sm={6}>
          <TextInput
            fullWidth
            source="backCategory"
            validate={[required()]}
            helperText="Doit correspondre à la valeur exacte dans le CRM"
          />
        </Grid>
        <Grid item sm={6}>
          <TextInput
            fullWidth
            source="backSubCategory"
            validate={[required()]}
            helperText="Doit correspondre à la valeur exacte dans le CRM"
          />
        </Grid>
      </Grid>
    </SimpleForm>
  );
};
